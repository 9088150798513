<template>
	<div class="about">
		<div :class="mainNavIsHidden ? 'main-nav-hidden-versions' : 'versions'">
			<span>{{ languageStringsAbout.serverVersion }}: {{ serverVersion.serverVersion }}</span>
			<span>{{ languageStringsAbout.operatorAppVersion }}: {{ operatorAppsVersion }}</span>
		</div>
		<div class="about-contents">
			<h1>{{ languageStringsAbout.playerPanelDocumentation }}</h1>
			<p style="text-align: center;">{{ languageStringsAbout.restrictedDisclaimer }}</p>
			<h3 class="heading">{{ languageStringsAbout.registerNewUser }}</h3>
			<ol>
				<li v-for="(item, index) in languageStringsAbout.registerNewUserOL" :key="index">{{ item }}</li>
			</ol>
			<h3 class="heading">{{ languageStringsAbout.changePassword }}</h3>
			<ol>
				<li v-for="(item, index) in languageStringsAbout.changePasswordOL" :key="index">{{ item }}</li>
			</ol>
			<h3 class="heading">{{ languageStringsAbout.forgotPassword }}</h3>
			<ol>
				<li v-for="(item, index) in languageStringsAbout.forgotPasswordOL" :key="index">{{ item }}</li>
			</ol>
			<h3 class="heading">{{ languageStringsAbout.verifyAccount }}</h3>
			<ol>
				<li v-for="(item, index) in languageStringsAbout.verifyAccountOL" :key="index">{{ item }}</li>
			</ol>
		</div>
	</div>
</template>

<script>
export default {
	name: "About",
	props: {
		serverVersion: Object,
		isMobile: Boolean,
		mainNavIsHidden: Boolean,
	},
	data() {
		return {
			languageStringsAbout: require(`../dependencies/about-${this.countryCode}.json`),
		};
	},
};
</script>

<style scoped>
h3 {
	text-align: left;
}

h3.heading {
	/* background-color: whitesmoke; */
	padding: 15px;
	/* color: #6305C9 ; */
}

h3.heading {
	margin-top: 3em;
}

.about-contents {
	width: 90%;
	padding: 15px;
	margin: 15px auto 30px;
	/* background-color: whitesmoke; */
	border-radius: 0.25em;
	/* color: #6305C9; */
}

.versions {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	margin: 5px 15px;
	text-align: center;
	color: #6305C9;
}

.main-nav-hidden-versions {
	margin: 5px 15px;
	margin-top: 117px;
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	text-align: center;
	color: #6305C9;
}

#main-nav-hidden {
	height: 120vh;
}

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	.about-contents {
		margin-bottom: 200px;
	}
}

@media (max-width:767px) {
	#main-nav-hidden {
		height: 1420px;
	}
}
</style>
